import { reactive, computed, onMounted } from '@vue/composition-api'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

/* eslint-disable-next-line */
export default (ctx) => {
  const formData = reactive({
    id: null,
    page_url: '',
    desktop_banner: [],
    mobile_banner: [],
    is_event: false,
    event_start_date: '',
    event_end_date: '',
    folder_name: null,
    event: {
      event_desktop_banner: [],
      event_mobile_banner: [],
      event_background_color: '',
      event_activity_image: [],
      event_activity_content: '',
      event_type: 'gallery',
      event_video_type: 'video_youtube',
      event_video_url: '',
      event_video_thumbnail: '',
      event_video_file: []
    },
    updated_at: null,
    updated_by: null,
    created_by: null,
  })

  onMounted(() => {
    ctx.root.$store.dispatch(`banner/fetchAllLang`)
  })

  const prepareFormData = async (result) => {
    formData.id = result.id
    formData.updated_at = result.updated_at
    formData.updated_by = result.updated_by
    formData.created_by = result.created_by
    formData.revision_id = result.revision_id
    formData.page_url = ctx.root.$store.state.banner.pageList.find(
      (v) => v.value === result.page_url,
    )
    formData.desktop_banner_id = result.desktop_banner_id
    formData.mobile_banner_id = result.mobile_banner_id
    formData.desktop_banner = orderBy(result.desktop_banner.items, ['image_index'], ['asc'])

    if (!result.folder_name) {
      const folders = ['Gallery', 'Mobile Gallery', 'Event']
      const randomUuid = uuidv4()
      formData.folder_name = randomUuid
      await createFolder(`banner/${randomUuid}`, folders)
    } else {
      formData.folder_name = result.folder_name
    }

    const mobileBanner = get(result, ['mobile_banner', 'items'])
    if (mobileBanner) {
      formData.mobile_banner = orderBy(mobileBanner, ['image_index'], ['asc'])
    }

    formData.is_event = result.is_event

    if (result.custom_banner && result.is_event) {
      const eventDesktopBanner = get(result, ['custom_banner', 'event_desktop_banner'], [])
      const eventMobileBanner = get(result, ['custom_banner', 'event_mobile_banner'], [])

      formData.event = {
        ...formData.event,
        ...result.custom_banner,
        event_desktop_banner: orderBy(eventDesktopBanner, ['image_index'], ['asc']),
        event_mobile_banner: orderBy(eventMobileBanner, ['image_index'], ['asc']),
      }

      formData.event_start_date = result.custom_banner.event_start_date
      formData.event_end_date = result.custom_banner.event_end_date
    }
  }

  const fetchByRouteId = async () => {
    const revision_id = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Gallery', 'Mobile Gallery', 'Event']
    if (revision_id) {
      ctx.root.$vs.loading()
      await ctx.root.$store.dispatch('banner/fetchOne', revision_id)
      .then((result) => {
        prepareFormData(result)
        if (!formData.folder_name) {
          const randomUuid = uuidv4()
          formData.folder_name = randomUuid
        }
        return createFolder(`banner/${formData.folder_name}`, folders)
      })
      .then(() => ctx.root.$vs.loading.close())
    } else {
      const randomUuid = uuidv4()
      ctx.root.$vs.loading()
      formData.folder_name = randomUuid
      await createFolder(`banner/${randomUuid}`, folders)
      ctx.root.$vs.loading.close()
    }
  }

  const existingOnDb = computed(() =>
    ctx.root.$store.state.banner.all_items,
  )

  const remainingPageList = computed(() => {
    console.log('ctx.root.$store.state.banner.pageList: ', ctx.root.$store.state.banner.pageList)
    const pageList = get(ctx.root.$store, ['state', 'banner', 'pageList'], [])
    return pageList.filter(
      (v) => !existingOnDb.value
        .filter(v => v.lang === ctx.root.$store.state.locale.currentLang)
        .find((item) => {
          return item.page_url === v.value
        }),
    )
  })

  const existLangByUrl = computed(() => {
    const result = {}

    existingOnDb.value.forEach((value) => {
      if (isEmpty(result[value.page_url])) result[value.page_url] = []
      result[value.page_url].push(value.lang)
    })

    return result
  })

  const pageList = computed(() => {
    if (formData.id) {
      return [formData.page_url]
    }

    if (ctx.root.$store.state.banner.items.length > 0) {
      return remainingPageList.value
    }

    return ctx.root.$store.state.banner.pageList
  })

  return {
    formData,
    pageList,
    remainingPageList,
    prepareFormData,
    fetchByRouteId,
    existLangByUrl
  }
}
