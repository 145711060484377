<template>
  <div>
    <ValidationObserver ref="form" v-slot="ctx">
      <form>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>หน้าจอที่แสดง *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="หน้าจอที่แสดง"
                rules="required"
              >
                <v-select
                  v-model="formData.page_url"
                  :options="pageList"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :disabled="!!$route.params.id"
                  placeholder="เลือกหน้าจอแสดง"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <vs-divider />
          <FormLayout>
            <div class="w-full text-center mb-3">
              ตำแหน่งรูปภาพหลักของหน้า PC * (.jpg)
              <br>
              <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
              <br>
              <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
            </div>
            <div class="w-full">
              <ValidationProvider
                v-slot="{ errors }"
                name="รูปภาพหลักของหน้า"
                rules="required|lh_ratio:16,9"
              >
                <GalleryForm
                  :folder="`${baseFolder}/banner/${formData.folder_name}/Gallery`"
                  v-model="formData.desktop_banner"
                  has-alt-text
                  has-ex-url
                  selection-name="cld-desktop-banner"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </FormLayout>
          <vs-divider v-show="formData.page_url.value === 'home'" />
          <FormLayout v-show="formData.page_url.value === 'home'">
            <div class="w-full text-center mb-3 border-t border-danger">
              ตำแหน่งรูปภาพหลักของหน้า Mobile * (.jpg ขนาด 1080 x 1920 px) ภาพขนาดไม่เกิน
              300 KB
            </div>
            <div class="w-full">
              <GalleryForm
                :folder="`${baseFolder}/banner/${formData.folder_name}/Mobile Gallery`"
                v-model="formData.mobile_banner"
                has-alt-text
                has-ex-url
                has-selectable
                selection-name="cld-mobile-banner"
              />
            </div>
          </FormLayout>
          <FormLayout>
            <div class="vx-col w-full md:w-9/12 ml-auto">
              <vs-checkbox v-model="formData.is_event">
                ถ้าต้องการสร้างชิ้นงาน Banner (กรุณาคลิกที่ปุ่ม)
              </vs-checkbox>
            </div>
          </FormLayout>
        </vx-card>
        <vx-card 
          v-show="formData.is_event" 
          class="mb-base">
          <BannerFormEvent
            :folder="`${baseFolder}/banner/${formData.folder_name}/Event`"
            v-model="formData"
          />
        </vx-card>
        <vx-card>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel />
            <FormContent>
              <vs-button 
                class="mr-3 mb-2" 
                @click="submit(ctx)"
              >
                {{ formData.id ? 'Update' : 'Submit' }}
              </vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, computed, watch } from '@vue/composition-api'
import VSelect from 'vue-select'
import GalleryForm from '@/components/GalleryForm'
import useDatepicker from '@/use/useDatepicker'
import useCrud from '@/use/useCrud'
import useBannerUtil from '@/use/useBannerUtil'
import BannerFormEvent from '@/components/BannerFormEvent'
import env from '@/env'

export default {
  name: 'BannerForm',
  components: {
    BannerFormEvent,
    GalleryForm,
    VSelect,
  },
  setup(props, ctx) {
    const { formData, pageList, fetchByRouteId } = useBannerUtil(ctx, 'banner')
    const crudForm = useCrud(ctx, 'banner')
    crudForm.routePrefix.value = 'banner'

    onMounted(() => {
      fetchByRouteId()
    })

    const submit = (ctx) => {
      crudForm.addItem(ctx, formData)
    }

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    watch(
      () => formData.event.event_type,
      () => {
        if (ctx.refs.form && ctx.refs.form.reset) {
          ctx.refs.form.reset()
        }
      }
    )

    return {
      formatDate: crudForm.formatDate,
      formData,
      pageList,
      submit,
      useDatepicker: useDatepicker(),
      baseFolder
    }
  },
}
</script>
